import { useEffect, useState } from "react";

import { stateUpdater } from "utils/helper";
import { fetchAllBranches } from "apis/misc";

export default function useGetBranchOptions() {
  const [state, setState] = useState({ options: [], isLoading: false });
  const updateState = stateUpdater(setState);

  function getData() {
    updateState("isLoading", true);
    fetchAllBranches()
      .then((resp) => {
        updateState(
          "options",
          resp.data.data.map((inst) => ({ label: inst.name, value: inst }))
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateState("isLoading", false);
      });
  }

  useEffect(getData, []);

  return { ...state };
}
