import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./index/App";
import config from "./config/config";

// Sentry.init({
//   dsn: config.sentryKey,
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   tracesSampleRate: 0.5,
//   replaysSessionSampleRate: 0.5,
//   replaysOnErrorSampleRate: 1.0,
//   // enabled: process.env.NODE_ENV === "PROD",
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
