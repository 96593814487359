import { Navigate } from "react-router-dom";
import { isLoggedIn } from "utils/helper";

export function useWithAuth() {
  function noAuthRedirect() {
    return <Navigate to="/contests" />;
  }
  function authorize(comp, roles, redirect) {
    if (!Array.isArray(roles)) roles = [roles];

    if (!isLoggedIn()) return noAuthRedirect();

    const userRole = localStorage.getItem("role");
    if (roles.some((role) => role === userRole)) return comp;
    else return noAuthRedirect();
  }
  function auth(roles) {
    if (!Array.isArray(roles)) roles = [roles];

    if (!isLoggedIn()) return false;

    const userRole = localStorage.getItem("role");
    if (roles.some((role) => role === userRole)) return true;
    else return false;
  }
  return { authorize, auth };
}
