import { useEffect, useState } from "react";

import { stateUpdater } from "utils/helper";
import { fetchAllSpecialisations } from "apis/misc";

import useQueryParams from "hooks/useQueryParams";

export default function useGetSpecialisationOptions() {
  const [state, setState] = useState({
    options: [],
    isLoading: false,
    selectedSpecialization: null,
  });

  const updateState = stateUpdater(setState);

  const { getParams } = useQueryParams();
  const { specialisationId } = getParams();

  function getData() {
    updateState("isLoading", true);
    fetchAllSpecialisations()
      .then((resp) => {
        updateState(
          "options",
          resp.data.data.map((inst) => ({ label: inst.name, value: inst })),
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateState("isLoading", false);
      });
  }

  useEffect(getData, []);
  useEffect(() => {
    const selectedSpecializations = state.options.find((f) => f.value._id === specialisationId);
    updateState(
      "selectedSpecialization",
      selectedSpecializations !== undefined ? selectedSpecializations : null,
    );
  }, [state.options.length]);

  return { ...state };
}
