import { patchStudentProfile, getValidationStatus } from "apis/user";
import { useState, useEffect } from "react";

import { object, string, number } from "yup";
import { routes } from "index/Routes";

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  gender: string().required(),
  fullStackExp: string().required(),
  platform_codechef: string().required(),
  platform_leetcode: string().required(),
  platform_codeforces: string().required(),
  platform_hackerrank: string().required(),
  linkedinId: string().required(),
  githubId: string().required(),
  institutionId: string().required(),
  specialisationId: string().required(),
  branchId: string().required(),
  semester: number().required(),
  instRollNumber: string().required(),
  instEmailId: string().email("*Please enter valid Email").required(),
  passoutYear: string().required(),
  technical: string().required(),
});
const schemaForNonTecnical = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  gender: string().required(),
  institutionId: string().required(),
  specialisationId: string().required(),
  branchId: string().required(),
  semester: number().required(),
  passoutYear: string().required(),
  instRollNumber: string().required(),
  instEmailId: string().email("*Please enter valid Email").required(),
  technical: string().required(),
});

export function useStudentRegistration() {
  const [state, setState] = useState({
    formData: {},
    errors: {},
    isLoadingSubmit: false,
    technical: "false",
  });

  const updateFormData = (name, value) => {
    setState((prev) => ({ ...prev, formData: { ...prev.formData, [name]: value } }));
  };

  function handleChangeForm(e) {
    let { name, value } = e.target;
    if (name === "fullStackExp") value = !!value;
    updateFormData(name, value);
  }

  function handleChangeRadio(e) {
    let { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      technical: value,
    }));
  }

  function handleChangeDropdown(type, e) {
    switch (type) {
      case "INSTITUTION":
        updateFormData("institutionId", e);
        break;

      case "SPECIALISATION":
        updateFormData("specialisationId", e);
        break;

      case "BRANCH":
        updateFormData("branchId", e);
        break;

      case "SEMESTER":
        updateFormData("semester", e);
        break;
      case "PASSOUT":
        updateFormData("passoutYear", e);
        break;
      default:
        return;
    }
  }

  async function handleSubmitForm(e) {
    e.preventDefault();

    let body = {};
    setState((prev) => ({ ...prev, errors: {} }));

    try {
      body = {
        ...state.formData,
        institutionId: state.formData.institutionId?.value._id,
        specialisationId: state.formData.specialisationId?.value._id,
        branchId: state.formData.branchId?.value._id,
        semester: state.formData.semester?.value,
        passoutYear: state.formData?.passoutYear?.value,
        technical: state.technical === "true" ? true : false,
      };
    } catch (err) {
      console.log(err);
    }

    for (const key in body) {
      if (!key.length) delete body[key];
    }

    delete body.institution;
    delete body.specialisation;
    delete body.branch;
    try {
      const schemaToValidate = state.technical === "true" ? schema : schemaForNonTecnical;
      console.log(body);
      await schemaToValidate.validate(body, { abortEarly: false });
      setState((prev) => ({ ...prev, isLoadingSubmit: true }));
      const codeforcesUserId = state.formData.platform_codeforces;
      getValidationStatus("codeforces", codeforcesUserId)
        .then((resp) => {
          const codeforcesResponse = resp;
          const codeforcesIsValid = codeforcesResponse.data.data.isValid;
          if (state.technical === "true") {
            if (codeforcesIsValid) {
              patchStudentProfile(body)
                .then(() => {
                  window.location.href = `${routes.CodingProfileStudent}?platform=codechef`;
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setState((prev) => ({ ...prev, isLoadingSubmit: false }));
                });
            }
            if (!codeforcesIsValid) {
              setState((prevState) => ({
                ...prevState,
                isLoadingSubmit: false,
                errors: {
                  ...prevState.errors,
                  platform_codeforces: { message: "Please enter a valid codeforces username" },
                },
              }));
            }
          } else {
            patchStudentProfile(body)
              .then(() => {
                window.location.href = `${routes.CodingProfileStudent}?platform=codechef`;
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setState((prev) => ({ ...prev, isLoadingSubmit: false }));
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, isLoadingSubmit: false }));
        });
    } catch (err) {
      console.log(err);
      let errs = {};
      err.inner.forEach((er) => {
        errs[er.path] = er;
      });
      console.log(errs);
      setState((prev) => ({ ...prev, errors: errs }));
    }
  }

  return { state, handleChangeForm, handleSubmitForm, handleChangeDropdown, handleChangeRadio };
}
