import style from "./Button.module.scss";
import clsx from "clsx";
import { Button as JoyButton } from "@mui/joy";

interface PropTypes {
  className?: string;
  children?: React.ReactNode;
  onClick?: (id?: any) => void;
  disabled?: boolean;
  variant?: "solid" | "soft" | "outlined" | "plain";
  icon?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  style?: any;
  id?: string;
  size?: "sm" | "md" | "lg";
  color?: any;
  startDecorator?: any;
  loading?: boolean;
}

export default function Button({
  className = "button",
  children,
  onClick,
  variant = "solid",
  icon,
  ...props
}: PropTypes) {
  const buttonClassName = clsx(style.button, className, {
    // [style["border-button"]]: variant === "border",
    // [style["warning-button"]]: variant === "warning",
    [style["icon-button"]]: icon,
  });

  return (
    <JoyButton
      className={buttonClassName}
      {...props}
      onClick={onClick}
      size={props.size}
      variant={variant}
      sx={{
        "--joy-palette-primary-solidBg": "#1176AE",
      }}
    >
      {icon && <span className={style.icon}>{icon}</span>}
      {children}
    </JoyButton>
  );
}
