import React from "react";
import styles from "./Footer.module.scss";

import { useNavigate } from "react-router-dom";

import logo from "assets/images/logo.png";
import fbdark from "assets/images/facebook.svg";
import insta from "assets/images/instagram.svg";
import twitter from "assets/images/twitter.svg";

// import links from "utils/links.json";

import PreFooter from "./PreFooter";

const LINKS = [
  {
    heading: "Contests",
    headingRoute: "/contests",
  },
];

const Footer = () => {
  // const location = useLocation();
  const navigate = useNavigate();

  function openHome() {}

  function linkTypeCheck(link) {
    if (link.heading === "home") openHome();
    else navigate(link.headingRoute);
  }

  return (
    <>
      <PreFooter />

      <div className={styles.footerWrapper}>
        <div className={styles.logo} onClick={openHome}>
          <img src={logo} alt="Logo" />
        </div>
        <div className={styles.midFooter}>
          {LINKS.map((link, index) => (
            <div className={styles.section} key={link.heading}>
              <p className={styles.heading} onClick={linkTypeCheck.bind(this, link)}>
                {link.heading}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.socialWrapper}>
          {/* <p>Follow us</p> */}
          {/* <div className={styles.socialLinks}>
            <div className={styles.socialLink}>
              <a href={links.socialMedia.facebook} target="_blank" rel="noopener noreferrer">
                <img src={fbdark} alt="" />
              </a>
            </div>
            <div className={styles.socialLink}>
              <a href={links.socialMedia.instagram} target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="" />
              </a>
            </div>
            <div className={styles.socialLink}>
              <a href={links.socialMedia.twitter} target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt="" />
              </a>
            </div>
          </div> */}
        </div>
        <div className={styles.copyrightWrapper}>
          <p>Copyright © 2022 CCC.Training</p>
          <p>
            Contact: <a href="mailto:info@ccc.training">info@ccc.training</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
