import axios from "axios";
import { toast } from "react-toastify";
import axiosRetry from "axios-retry";
import config from "../config/config.js";

const BASE_URL = config.apiBaseUrl;

function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

export function refreshAccessToken() {
  const refreshToken = getRefreshToken();
  return axios.post(`${BASE_URL}/auth/token/refresh`, { refreshToken: refreshToken });
}

export function getCurrentDateTime() {
  return new Promise((resolve, reject) => {
    axios
      .get("http://worldtimeapi.org/api/ip")
      .then((response) => {
        const data = response.data;
        const dateTimeFromApi = new Date(data.utc_datetime);
        const systemDateTime = new Date();
        const acceptableDifference = 60000;
        const timeDifference = Math.abs(dateTimeFromApi.getTime() - systemDateTime.getTime());
        resolve({
          dateTimeFromApi,
          isTimeDifferenceAcceptable: timeDifference <= acceptableDifference,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const Client = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

axiosRetry(Client, { retries: 3 });
Client.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status === 402) {
      if (error.response.data.error.message === "No License Found") {
        toast.error("Contact admin for licenses");
      } else if (window.location.pathname !== "/error/subscribe-error") {
        return (window.location.href = "/error/subscribe-error");
      }
      // if (window.location.pathname !== "/error/subscribe-error")
      //
    }
    if (error.response && error.response.status === 403) {
      localStorage.clear();
      return (window.location.href = "/contests");
    }

    return Promise.reject(error);
  },
);

export const getRequest = (path, query, responseType = "json") => {
  return Client.get(`${BASE_URL}${path}`, {
    params: query,
    responseType: responseType,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRequestMultiPart = (path, data) => {
  return Client.post(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postRequest = (path, data) => {
  return Client.post(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRequestWithQuery = (path, query, data) => {
  return Client.post(`${BASE_URL}${path}`, data, {
    params: query,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const patchRequest = (path, data, query) => {
  return Client.patch(`${BASE_URL}${path}`, data, {
    params: query,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const multiGetRequest = async (paths) => {
  let arr = [];

  paths.forEach((item) => {
    arr.push(Client.get(`${BASE_URL}${item}`, {}));
  });
  return axios.all(arr);
};

export const multiPostRequest = async (configs) => {
  let arr = [];
  configs.forEach((item) => {
    arr.push(Client.post(`${BASE_URL}${item.url}`, item.data, {}));
  });
  return axios.all(arr);
};

export const putRequest = (path, data) => {
  return Client.put(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const headRequest = (path) => {
  return Client.head(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteRequest = (path, body) => {
  return Client.delete(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
  });
};

export const deleteRequestInParams = (path, params) => {
  return Client.delete(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  });
};
