import { postLoginCollege } from "apis/auth";
import { useState, useEffect } from "react";

export function useLogin() {
  const [state, setState] = useState({
    formData: {},
    isPassVisible: false,
    isValid: true,
  });

  function handleChangeForm(e) {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, formData: { ...prev.formData, [name]: value } }));
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    const body = {
      email: state.formData.email,
      password: state.formData.password,
    };

    postLoginCollege(body)
      .then((resp) => {
        localStorage.setItem("authToken", resp.data.data.token);
        localStorage.setItem("refreshToken", resp.data.data.refreshToken);
        localStorage.setItem("role", "institutionAdmin");
        window.location.href = "/coding-profiles";
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setState((prev) => ({ ...prev, isValid: false }));
        }
      });
  }

  function togglePassVisibility(e) {
    e.preventDefault();
    setState((prev) => ({ ...prev, isPassVisible: !prev.isPassVisible }));
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, isValid: true }));
  }, [state.formData]);

  return { state, handleChangeForm, handleSubmitForm, togglePassVisibility };
}
