import style from "./Preloader.module.scss";

import clsx from "clsx";
import BounceLoader from "react-spinners/BarLoader";

import vars from "utils/vars.json";

const override = {
  display: "block",
};

export default function Preloader({ inline }: { inline?: boolean }) {
  return (
    <div
      data-testid="pre-loader"
      className={clsx(
        style.wrapper,
        !inline ? style.fullPage : style.inline,
        "withPadding"
      )}
    >
      <BounceLoader
        color={vars.primaryColor}
        loading={true}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
