import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function useQueryParams(defaultParams) {
  const [searchParams, setSearchParams] = useSearchParams();

  function updateParams(update) {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ ...update },
      });

      Object.entries(update).forEach(([key, value]) => {
        if (value === null) {
          newParams.delete(key);
        } else {
          newParams.set(key, value);
        }
      });
      return newParams;
    });
  }

  function getParams() {
    const resp = {};
    for (const key of searchParams.keys()) {
      resp[key] = searchParams.get(key);
    }
    return resp;
  }

  function setIntialParams() {
    const toUpdateParams = { ...defaultParams };

    for (const key in defaultParams) {
      if (searchParams.get(key)) {
        delete toUpdateParams[key];
      }
      updateParams(toUpdateParams);
    }
  }

  useEffect(() => {
    setIntialParams();
  }, []);

  return { searchParams, updateParams, getParams };
}
