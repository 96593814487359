import clsx from "clsx";
import style from "./Input.module.scss";
import Tooltip from "@mui/material/Tooltip";

interface PropTypes {
  label?: string | React.ReactNode;
  validationErrors?: any;
  className?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  value?: any;
  readOnly?: boolean;
  classForValidation?: string;
  required?: boolean;
  onChange?: (value: any) => void;
  defaultValue?: any;
  onKeyDown?: (value: any) => void;
  disabled?: boolean;
  tooltip?: any;
}

export default function Input({
  label,
  validationErrors,
  className,
  classForValidation,
  ...props
}: PropTypes) {
  return (
    <div
      className={clsx(style.wrapper, className)}
      aria-disabled={props.disabled}
      title={props.tooltip}
    >
      <Tooltip title={props.tooltip}>
        {typeof label === "string" ? <label>{label}</label> : <>{label}</>}
      </Tooltip>
      <input {...props} />

      {validationErrors && validationErrors[props.name] && (
        <p className={classForValidation}>{validationErrors[props.name].message}</p>
      )}
    </div>
  );
}
