import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import clsx from "clsx";

import style from "./InputRadio.module.scss";

interface PropTypes {
  label?: string;
  options: any[];
  name?: string;
  className?: string;
  validationErrors?: any;
  value?: boolean;
  classForValidation?: string;
  onChange?: any;
  defaultValue?: any;
  labelClass?: any;
}

export default function InputRadio({
  label,
  options,
  name,
  className,
  validationErrors,
  classForValidation,
  value,
  labelClass,
  ...props
}: PropTypes) {
  return (
    <div className={clsx(style.wrapper, className)}>
      <FormControl>
        <label style={{ marginBottom: "1em" }} className={labelClass}>
          {label}
        </label>

        <RadioGroup
          sx={{ marginLeft: "8px" }}
          row={true}
          name={name}
          {...props}
          defaultValue={props.defaultValue}
        >
          {options.map(({ value: valueCheckbox, label }) => (
            <FormControlLabel
              key={valueCheckbox}
              value={valueCheckbox}
              control={
                value ? (
                  <Radio
                    checked={value === valueCheckbox}
                    sx={{ padding: 0, marginRight: "10px" }}
                  />
                ) : (
                  <Radio sx={{ padding: 0, marginRight: "6px" }} />
                )
              }
              label={label}
            />
          ))}
        </RadioGroup>

        {validationErrors && validationErrors[name] && (
          <p className={classForValidation}>{validationErrors[name].message}</p>
        )}
      </FormControl>
    </div>
  );
}
