import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppContext } from "contexts/AppContext";
import { isLoggedIn } from "utils/helper";

import { routes } from "index/Routes";

export default function useGlobalEffects() {
  const appContext = useAppContext();
  const { isOpenProfileUpdateModal } = appContext?.state || false;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;

    window.scrollTo(0, 0);
    if (!isLoggedIn() && pathname === `${routes.Error}/subscribe-error`) navigate("/");
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (!isOpenProfileUpdateModal) document.body.style.overflow = "unset";
    else document.body.style.overflow = "hidden";
  }, [isOpenProfileUpdateModal]);
}
