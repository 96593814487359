import style from "./ErrorBoundaryHandler.module.scss";
import RedirectImg from "assets/images/redirect.svg";
import Button from "components-shared/Button/Button";
import { handleLogout } from "utils/helper";

export default function ErrorBoundaryHandler() {
  return (
    <div className={style.wrapper}>
      <img src={RedirectImg} alt="Lost" />
      <a href="/">
        <Button onClick={handleLogout}> Return To Home Page</Button>
      </a>
    </div>
  );
}
