import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";

const ErrorPage = lazy(() => import("components/ErrorPage/ErrorPage"));
const TimeError = lazy(() => import("components/ErrorPage/TimeError/TimeError"));

export default function RouterError() {
  return (
    <Routes>
      <Route path="/subscribe-error" element={<ErrorPage />} />
      <Route path="/time-error" element={<TimeError />} />
    </Routes>
  );
}
