import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "components/Login/Login";

export default function RouterAuth() {
  return (
    <Routes>
      <Route path="login/college" element={<Login />} />
    </Routes>
  );
}
